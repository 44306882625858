<template>
	<footer :class="$getStyle.set($style).add('footer').list" class="section"></footer>
</template>

<script>
	export default {
		name : "v-footer"
	}
</script>
<style lang="scss" module>

	@import "../assets/scss/vars";

	.footer{
		margin-top: 50px;
		height: 100px;
		background: $COLOR_BLUE;
	}
</style>

