<template>
	<section class="section section--mini">
		<h1 class="section__h">Регистрация клиента</h1>
		<section class="section section--white">
			<v-form action="users.register" @success="submit" :class="$getStyle.set($style).add('reg').list">
				<div class="field field--fill" :class="$getStyle.set($style).add('reg', 'field').list">
					<span class="field__legend">E-mail</span>
					<input type="text" placeholder="Mail" name="mail" class="input">
				</div>
				<!--<div class="field field&#45;&#45;fill" :class="$getStyle.set($style).add('reg', 'field').list">-->
					<!--<span class="field__legend">Номер рассчетного счета</span>-->
					<!--<input type="text" placeholder="Р/С" name="rs" class="input">-->
				<!--</div>-->
				<div class="field field--fill" :class="$getStyle.set($style).add('reg', 'field').list">
					<span class="field__legend">ИНН</span>
					<input type="text" placeholder="ИНН" name="inn" class="input">
				</div>
				<!--<div class="field field&#45;&#45;fill" :class="$getStyle.set($style).add('reg', 'field').list">-->
					<!--<span class="field__legend">БИК</span>-->
					<!--<input type="text" placeholder="БИК" name="bik" class="input">-->
				<!--</div>-->
				<input type="hidden" name="company" :value="$root.accountData.a_token">
				<button class="button" :class="$getStyle.set($style).add('reg', 'button').list">Зарегистрировать</button>
			</v-form>
		</section>
		<br>
		<section class="section section--white" style="text-align: center;padding:15px;" v-if="token">
			<a :href="`/cabinet/?token=${token}`">{{protocol}}//{{domain}}:{{port}}/cabinet/?token={{token}}</a>
		</section>

	</section>
</template>
<script type="text/javascript">
	export default {
		name : 'page-register',
		data(){
			return {
				token : false,
				protocol : location.protocol,
				domain : document.domain,
				port : location.port
			}
		},
		methods : {
			submit(data){

				if(data.error){
					alert(data.error);
				}else{
					this.token = data.result;
					alert("Успешно!");
				}

			}
		}
	}
</script>
<style lang="scss" module>

	@import "../assets/scss/vars";

	.reg{
		padding: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;

		&__field, &__button{
			width: (100%/5)-1;
			margin-bottom: 5px;
		}

	}

</style>