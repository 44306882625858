<template>
	<section class="section section--mini">
		<h1 class="section__h">Просмотр документов</h1>
		<form class="section" action="documents.archive" ref="form" @submit.prevent="resetPage">
			<div :class="$getStyle.set($style).add('filter').list" class="section--white section" v-if="$root.authorized">
				<div class="field field--fill">
					<span class="field__legend">Пользователь</span>
					<select class="select" name="user" @change="resetPage" ref="user">
						<option value="0">Любой</option>
						<option :value="u.c_id" v-for="u in $root.clients">{{u.c_name}}</option>
					</select>
				</div>
				<div class="field field--fill" style="margin-left: 50px;">
					<span class="field__legend">Тип документа</span>
					<select class="select" name="type" @change="resetPage" ref="type">
						<option value="0">Любой</option>
						<option value="1">Открытые счета</option>
						<option value="3">Закрытые счета</option>
						<option value="2">Акты</option>
						<option value="4">Договоры</option>
					</select>
				</div>
				<div class="field field--fill" style="margin-left: 50px;">
					<span class="field__legend">ИНН</span>
					<input name="inn" class="input" @change="resetPage" />
				</div>
			</div>
			<br>
			<div class="section--white section">
				<input type="hidden" name="offset" :value="page * limit">
				<input type="hidden" name="limit" :value="limit">
				<table :class="$getStyle.set($style).add('table').list" ref="table">
					<v-archive-item v-for="(item,k) of items" @delete="items.splice(k, 1)" :item="item"></v-archive-item>
				</table>
				<br>
				<v-loader v-if="loading"></v-loader>
				<p style="text-align: center;color: dimgray;padding: 15px;" v-if="!loading && !items.length">Ничего не найдено</p>
			</div>
		</form>
	</section>
</template>
<script type="text/javascript">
	export default {
		name : 'page-archive',
		data (){
			return {
				items : [],
				loading : false,
				page : 0,
				limit : 30
			};
		},
		props : {
			user : {
				default : 0
			}
		},
		components : {
			'v-archive-item' : require('../components/archive-item.vue').default
		},
		methods : {
			resetPage(){
				this.page = 0;
				this.items = [];
				this.$nextTick(() => {
					this.loadArchive();
				});
			},
			loadArchive(){

				if(!this.loading){

					this.loading = true;

					this.$api.sendForm(this.$refs.form).then(({data}) => {

						if(!data.result.length){
							window.removeEventListener('scroll', this.scroll);
						}else{
							this.items.push(...data.result);
							this.page++;
						}

						this.loading = false;

					});

				}

			},
			scroll(){

				let table = this.$refs.table;
				let height = window.innerHeight;
				let rect = table.getBoundingClientRect();

				if(rect.bottom < height + 400){
					this.loadArchive();
				}

			}
		},
		async mounted(){
			this.$nextTick(() => {

				this.$root.authorized && Array.prototype.slice.call(this.$refs.user.children).some((e) => {
					if(e.value === this.user){
						return (e.selected = true);
					}
				});

				this.$nextTick(() => {
					this.loadArchive();
					window.addEventListener('scroll', this.scroll);
				});

			});
		}
	};
</script>
<style module lang="scss">

	@import "../assets/scss/vars";

	.table{
		width: 100%;
	}

	.filter{
		padding: 15px;

		> *{
			display: inline-block;
			width: 300px;
		}

	}

</style>