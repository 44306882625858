<template>
	<section class="section section--mini">
		<div class="section--white" style="padding: 20px;">
			<h1 class="section__h">Настройки API</h1>
			<v-form action="companies.account" @success="submit" :class="$getStyle.set($style).add('form').list" ref="form">
				<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
					<p class="field__legend">Приватный токен</p>
					<input type="text" class="input" :value="$root.accountData.a_token" readonly>
				</div>
				<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
					<p class="field__legend">E-mail</p>
					<input type="text" class="input" name="mail">
				</div>
				<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
					<p class="field__legend">Скрипт-обработчик</p>
					<input type="text" placeholder="http://site.ru/script.php" name="url" class="input">
				</div>
				<button class="button" :class="$getStyle.set($style).add('form', 'button').list">Сохранить</button>
			</v-form>
		</div>
		<br>
		<div class="section__h"></div>
	</section>
</template>
<script type="text/javascript">

	export default {
		name : 'page-include',
		methods : {
			submit(data){

				if(data.error){
					alert(data.error);
				}else{
					alert('Сохранено!');
					for(let i in data.result){
						this.$root.accountData[i] = data.result[i];
					}
				}

			}
		},
		mounted(){

			let elems = this.$refs.form.$el.elements;
			let data = this.$root.accountData;

			elems.url.value = data.a_url;
			elems.mail.value = data.a_mail;

		}
	}
</script>

<style lang="scss" module>

	@import "../assets/scss/vars";

	.form{
		display: flex;
		align-items: stretch;
		justify-content: stretch;

		&__field{
			display: inline-block;
			margin-right: 10px;
		}

		&__button{
			padding: 0 20px;
		}


	}

</style>