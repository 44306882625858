<template>
	<section class="section section--mini" style="width: 600px">
		<h1 class="section__h">Регистрация</h1>
		<section class="section section--white">
			<v-form action="companies.register" @success="submit" :class="$getStyle.set($style).add('reg').list">
				<div class="field field--fill" :class="$getStyle.set($style).add('reg', 'field').list">
					<span class="field__legend">E-mail</span>
					<input type="text" placeholder="Mail" name="mail" class="input">
				</div>
				<!--<div class="field field&#45;&#45;fill" :class="$getStyle.set($style).add('reg', 'field').list">-->
					<!--<span class="field__legend">Номер рассчетного счета</span>-->
					<!--<input type="text" placeholder="Р/С" name="rs" class="input">-->
				<!--</div>-->
				<!--<div class="field field&#45;&#45;fill" :class="$getStyle.set($style).add('reg', 'field').list">-->
					<!--<span class="field__legend">ИНН</span>-->
					<!--<input type="text" placeholder="ИНН" name="inn" class="input">-->
				<!--</div>-->
				<!--<div class="field field&#45;&#45;fill" :class="$getStyle.set($style).add('reg', 'field').list">-->
					<!--<span class="field__legend">БИК</span>-->
					<!--<input type="text" placeholder="БИК" name="bik" class="input">-->
				<!--</div>-->
				<div class="field field--fill" :class="$getStyle.set($style).add('reg', 'field').list">
					<span class="field__legend">Пароль</span>
					<input type="password" placeholder="Пароль" name="password" class="input">
				</div>
				<button class="button" :class="$getStyle.set($style).add('reg', 'button').list">Зарегистрироваться</button>
			</v-form>
		</section>

	</section>
</template>
<script type="text/javascript">
	export default {
		name : 'page-register',
		methods : {
			submit(data){
				if(data.error){
					alert(data.error);
				}else{
					this.$emit('success');
				}
			}
		}
	}
</script>
<style lang="scss" module>

	@import "../assets/scss/vars";

	.reg{
		padding: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;

		&__field, &__button{
			width: (100%/3)-1;
			margin-bottom: 5px;
		}

	}

</style>