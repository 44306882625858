export default {
	'install' : function(Vue){


		function StyleController(controller){
			this.list = [];
			this.set(controller);
		}


		StyleController.set = function(controller){
			return new StyleController(controller);
		};


		StyleController.prototype = {
			constructor : StyleController,
			generate : function(block, element = null, mod = null){

				let style = block;

				if(element){
					style += `__${element}`;
				}

				if(mod){
					style += `--${mod}`;
				}

				return this.container[style];

			},
			set : function(controller){
				this.container = controller;
				return this;
			},
			get : function(){
				return this.list;
			},
			add : function(block, element, mod){

				let list = [], m;

				list.push(this.generate(block, element));

				if(mod instanceof Array){

					mod.forEach((m) => {
						list.push(this.generate(block, element, m));
					});

				}else if(typeof mod === 'object'){

					for(m in mod){
						mod[m] && list.push(this.generate(block, element, m));
					}

				}else if(mod){

					list.push(this.generate(block, element, mod));

				}

				this.list.push(...list);

				return this;

			},
			valueOf : function(){
				return this.list;
			},
			toString : function(){
				return this.list.join(' ');
			}

		};



		Vue.prototype.$getStyle = StyleController;


	}
};