<template>
	<section class="section">
		<v-header-client></v-header-client>
		<div :class="$getStyle.set($style).add('row').list" class="section section--mini">
			<v-form action="documents.generate.bill" @success="addBill" :class="$getStyle.set($style).add('row', 'col').list">
				<div class="field field--fill" style="width: 400px;">
					<span class="field__legend">Новый счет</span>
					<input type="text" placeholder="На сумму" name="sum" class="input">
				</div>
				<button class="button" style="width: 100px">Создать</button>
			</v-form>
		</div>
		<br>
		<page-archive ref="archive"></page-archive>
		<v-footer></v-footer>
	</section>
</template>
<script type="text/javascript">
	export default {
		name : 'page-cabinet',
		data (){
			return {

			};
		},
		methods : {
			addBill(data){

				if(data.error){
					alert(data.error);
				}else{
					alert('Успешно!');
					this.$refs.archive.resetPage();
				}

			}
		},
		mounted(){

		}
	};
</script>
<style module lang="scss">

	@import "../assets/scss/vars";

	.table{
		width: 100%;
	}

	.row{
		display: flex;
		justify-content: flex-start;

		&__col{
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
		}

	}

</style>