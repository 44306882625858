<template>
	<tr :class="$getStyle.set($style).add('tr').list">
		<td :class="$getStyle.set($style).add('tr', 'td').list">
			<span class="inf inf--blue">ID:{{item.c_id}}</span>
		</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">{{item.c_date}}</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">ИНН {{item.c_inn}}</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">{{item.c_name}}</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">Директор {{item.c_person}}</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">{{item.c_mail}}</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">
			<a class="btn btn--gray" :href="`/cabinet/?token=${item.c_token}`" target="_blank">Кабинет</a>
			<a class="btn btn--green" :href="`/archive/add/?user=${item.c_id}`" target="_blank">Выставить счет</a>
			<a class="btn btn--blue" :href="`/archive/?user=${item.c_id}`" target="_blank">Документы</a>
			<v-loader v-if="loading" :class="$getStyle.set($style).add('tr', 'loader').list"></v-loader>
		</td>
	</tr>
</template>

<script>
	export default {
		name : "v-clients-item",
		data(){
			return {
				loading : false
			}
		},
		props : {
			item : {
				required : true
			}
		}
	}
</script>

<style module lang="scss">

	@import "../assets/scss/vars";

	.tr{
		position: relative;

		&__td{
			padding: 10px;
			border-bottom: 1px solid rgba($COLOR_BLACK, 0.1);

			&:last-child{
				text-align: right;
			}

		}

		&__loader{
			background: rgba($COLOR_WHITE, 0.7);
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
		}

	}



</style>