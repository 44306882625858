<template>
	<section class="section section--mini">
		<h1 class="section__h">Быстрая смена реквизитов</h1>
		<div class="section--white" style="padding: 20px;">
			<form>
				<label :class="$getStyle.set($style).add('changebox', null, {'active' : $root.accountData.u_active == req.u_id}).list" v-for="req in $root.companies" @click.prevent="selectRequisite(req.u_id)">
					<input type="radio" name="id" :value="req.u_id" :class="$getStyle.set($style).add('changebox', 'input').list">
					<!--<a href="#" v-if="$root.accountData.u_active != req.u_id" :class="$getStyle.set($style).add('changebox', 'icon').list" @click.prevent.stop="deleteRequisite(req.u_id)">Удалить</a>-->
					<p :class="$getStyle.set($style).add('changebox', 'name').list">{{req.u_name||`Без имени`}}</p>
					<p :class="$getStyle.set($style).add('changebox', 'inn').list">{{req.u_inn}}</p>
				</label>
			</form>
			<br>
			<div v-if="change === false">
				<label :class="$getStyle.set($style).add('changebox').list" @click="change = true" style="background: #5D3D7F;">
					<p :class="$getStyle.set($style).add('changebox', 'name').list">+ Добавить реквизиты</p>
					<p :class="$getStyle.set($style).add('changebox', 'inn').list">ИНН, БИК, Р/С</p>
				</label>
			</div>
			<v-form action="companies.requisites.add" @success="addRequisite" :class="$getStyle.set($style).add('form').list" v-else>
				<br>
				<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
					<p class="field__legend">ИНН</p>
					<input type="text" placeholder="ИНН" name="inn"  class="input">
				</div>
				<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
					<p class="field__legend">БИК</p>
					<input type="text" placeholder="БИК" name="bik"  class="input">
				</div>
				<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
					<p class="field__legend">Номер рассчетного счета</p>
					<input type="text" placeholder="Р/С" name="rs"  class="input">
				</div>
				<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
					<p class="field__legend">Номер корпоративного счета</p>
					<input type="text" placeholder="К/С" name="ks"  class="input">
				</div>
				<button class="button" :class="$getStyle.set($style).add('form', 'button').list">Добавить</button>
			</v-form>
		</div>
		<br>
		<template v-if="+$root.accountData.u_active">
			<h1 class="section__h">Профиль</h1>
			<div class="section--white" style="padding: 20px;">
				<v-form action="companies.profile" @success="submit" :class="$getStyle.set($style).add('form').list" ref="form">
					<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
						<p class="field__legend">Генерировать акт через</p>
						<v-slider name="time_act" :options="{start:$root.companyData.u_time_act / 3600, range:{min:[-1,1],'50%':[24, 24], max:24*100}, connect: [true, false],  tooltips : true, format: {to:tooltipsTo,  from:tooltipsFrom}}"></v-slider>
					</div>
					<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
						<p class="field__legend">Длительность предоставления услуг</p>
						<v-slider name="time_duration" :options="{start:$root.companyData.u_time_duration / 3600, range:{min:[0,1],'50%':[24, 24], max:24*100}, connect: [true, false],  tooltips : true, format: {to:tooltipsTo,  from:tooltipsFrom}}"></v-slider>
					</div>
					<div class="field field--fill" :class="$getStyle.set($style).add('form', 'field').list">
						<p class="field__legend">Повторно выставлявлять счет за</p>
						<v-slider name="time_repeat" :options="{start:$root.companyData.u_time_repeat / 3600, range:{min:[-1,1],'50%':[24, 24], max:24*100}, connect: [true, false], tooltips : true,  format: {to:tooltipsTo,  from:tooltipsFrom}}"></v-slider>
					</div>
					<button class="button" :class="$getStyle.set($style).add('form', 'button').list">Сохранить</button>
				</v-form>
			</div>
			<br>
			<v-template-item title="Шаблон выставления счета" type="1"></v-template-item>
			<br>
			<v-template-item title="Шаблон выставления акта сверки" type="2"></v-template-item>
			<br>
			<v-template-item title="Шаблон договора" type="4"></v-template-item>
		</template>
	</section>
</template>
<script type="text/javascript">

	export default {
		name : 'page-profile',
		methods : {
			tooltipsTo(x){

				if(x < 0){
					return 'Выкл.';
				}else if(x == 0){
					return 'Моментально';
				}else if(x < 24){
					return (parseInt(x) + ' ч.');
				}else{
					return parseInt(Math.round(x) / 24) + ' дн.';
				}

			},
			tooltipsFrom(x){
				return x;
			},
			submit(data){

				if(data.error){
					alert(data.error);
				}else{
					alert('Сохранено!');
					for(let i in data.result){
						this.$root.companyData[i] = data.result[i];
					}
				}

			},
			selectRequisite(n){

				this.$api('companies.requisites.select', {id : n}).then(({data}) => {

					if(data.error){
						alert(data.error);
					}else{
						location.reload();
					}

				});

			},
			deleteRequisite(n){

				this.$api('companies.requisites.delete', {id:n}).then(({data}) => {

					if(data.error){
						alert(data.error);
					}else{
						this.$root.companies.splice(n, 1);
						this.$forceUpdate();
					}

				});

			},
			addRequisite(data){

				if(data.error){
					alert(data.error);
				}else{
					this.$root.companies.unshift(data.result);
					if(this.$root.companies.length === 1){
						location.reload();
					}
					this.change = false;
				}

			}
		},
		data(){
			return {
				change : false
			}
		},
		mounted(){

		}
	}
</script>
<style>
	@import "../../node_modules/nouislider/distribute/nouislider.min.css";

	.noUi-horizontal{
		height: 10px;
		margin: 10px 3px;
	}

	.noUi-horizontal .noUi-handle{
		width: 21px;
		height: 21px;
	}

	.noUi-horizontal .noUi-tooltip{
		bottom: auto;
		top: 120%;
	}

	.noUi-handle:before,
	.noUi-handle:after{
		display: none;
	}


	.noUi-connect{
		background: #34aadc;
	}

</style>
<style lang="scss" module>

	@import "../assets/scss/vars";

	.form{
		display: flex;
		align-items: stretch;
		justify-content: stretch;

		&__field{
			display: inline-block;
			margin-right: 10px;
		}

		&__button{
			padding: 0 20px;
		}


	}

	.changebox{
		display: inline-block;
		width: 15%;
		border-radius: 3px;
		margin: 0 0.5%;
		background: lightgray;
		box-sizing: border-box;
		color: $COLOR_WHITE;
		position: relative;
		padding: 20px 20px 20px 25px;
		text-align: right;

		&:hover{
			opacity: 0.8;
			background: $COLOR_BLUE;
		}

		&--active{
			background: $COLOR_BLUE;
			box-shadow: 0 0 4px rgba(0,0,0,0.5)
		}

		&__name{
			font-weight: bold;
			margin-bottom: 10px;
			font-size: 1.1em;
		}

		&__inn{
		}

		&__icon{

		}

		&__input{
			visibility: hidden;
			position: absolute;
		}

		&__icon{
			position: absolute;
			left: 10px;
			top: 7px;
			display: inline-block;
			font-size: 0.8em;
			font-style: normal;
			box-sizing: border-box;
			transition: all 0.3s;
		}

		&__input:checked ~ &__icon{
			background: $COLOR_BLUE_DARK;
		}

	}


</style>