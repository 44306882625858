<template>
	<section class="section section--mini" style="width: 600px;">
		<h1 class="section__h">Вход</h1>
		<section class="section section--white">
			<v-form action="companies.auth"  @success="submit" :class="$getStyle.set($style).add('auth').list">
				<div class="field field--fill" :class="$getStyle.set($style).add('auth', 'field').list">
					<span class="field__legend">E-mail</span>
					<input type="text" placeholder="Mail" name="mail" class="input">
				</div>
				<div class="field field--fill" :class="$getStyle.set($style).add('auth', 'field').list">
					<span class="field__legend">Пароль</span>
					<input type="password" placeholder="Пароль" name="password" class="input">
				</div>
				<button class="button" :class="$getStyle.set($style).add('auth', 'button').list">Войти</button>
			</v-form>
		</section>
	</section>
</template>
<script type="text/javascript">
	export default {
		name : 'page-auth',
		methods : {
			submit(data){

				if(data.error){
					alert(data.error);
				}else{
					this.$emit('success');
				}

			}
		}
	}
</script>
<style module lang="scss">

	@import "../assets/scss/vars";

	.auth{
		padding: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;

		&__field, &__button{
			width: (100%/3)-1;
			margin-bottom: 5px;
		}

	}
</style>