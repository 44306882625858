<template>
	<section class="section section--mini" v-if="item">
		<v-form action="documents.edit" @success="submit" ref="form" v-if="item.d_type == 3 || item.d_type == 1">
			<h1 class="section__h">Редактирование счета</h1>
			<div class="section--white section" style="padding:20px;display:flex;justify-content: space-between;align-items: stretch;">
				<div class="field field--fill" style="width: 16%;" v-show="item.d_time_start > 0">
					<span class="field__legend">Начало предоставления услуг</span>
					<input type="text" placeholder="ДД.ММ.ГГГГ ЧЧ:ММ" name="time_start" class="input">
				</div>
				<!--<div class="field field&#45;&#45;fill" style="width: 16%;">-->
					<!--<span class="field__legend">Тема документа</span>-->
					<!--<input type="text" placeholder="Введите заголовок" name="theme" class="input">-->
				<!--</div>-->
				<div class="field field--fill" style="width: 16%;">
					<span class="field__legend">Сумма</span>
					<input type="text" placeholder="Введите сумму" name="sum" class="input">
				</div>
				<div class="field field--fill" style="width: 16%;">
					<span class="field__legend">Клиент</span>
					<select class="select" name="user" ref="client">
						<option :value="u.c_id" v-for="u in $root.clients">{{u.c_name}}</option>
					</select>
				</div>
				<div class="field field--fill" style="width: 16%;">
					<span class="field__legend">Компания</span>
					<select class="select" name="company" ref="company">
						<option :value="u.u_id" v-for="u in $root.companies">{{u.u_name}}</option>
					</select>
				</div>
				<div class="field field--fill" style="width: 16%;">
					<p class="field__legend">Длительность предоставления услуг</p>
					<v-slider name="time_duration" :options="{start:item.d_time_duration / 3600, range:{min:[0,1],'50%':[24, 24], max:24*100}, connect: [true, false], tooltips : true,  format: {to:tooltipsTo,  from:tooltipsFrom}}"></v-slider>
				</div>
				<input type="hidden" name="id" :value="id">
				<button class="button" style="width: 16%">Сохранить</button>
			</div>
		</v-form>
	</section>
</template>
<script type="text/javascript">
	export default {
		name : 'page-archive-edit',
		data(){
			return {
				item : null,
				oldResult : '',
				newResult : '',
				opened : false,
			}
		},
		props : {
			id : {
				required : true
			}
		},
		methods : {
			tooltipsTo(x){

				if(x < 0){
					return 'Выкл.';
				}else if(x == 0){
					return 'Моментально';
				}else if(x < 24){
					return (parseInt(x) + ' ч.');
				}else{
					return parseInt(Math.round(x) / 24) + ' дн.';
				}

			},
			tooltipsFrom(x){
				return x;
			},
			openEditor(){
				this.opened = true;
				this.newResult = this.oldResult;
			},
			saveEditor(){
				this.$api('documents.edit', {html : this.newResult, id : this.id}).then(({data}) => {
					alert('Сохранено!');
					this.oldResult = this.newResult;
					this.opened = false;
				});
			},
			cancelEditor(){
				this.opened = false;
				this.newResult = this.oldResult;
			},
			submit(data){
				if(data.error){
					alert(data.error);
				}else{
					alert('Сохранено!');
				}
			}
		},
		async mounted(){

			this.$api('documents.get', {id : this.id, json : 1}).then(({data}) => {

				if(data.result){
					// this.oldResult = data.result.html;
					this.item = data.result;

					data.result.d_time_start > 0 && this.$nextTick(() => {
						let form = this.$refs.form.$el;
						form.elements.time_start.value = data.result.text_time_start;
						// form.elements.theme.value = data.result.d_name;
						form.elements.sum.value = data.result.d_sum;

						Array.prototype.slice.call(this.$refs.client.children).some((e) => {
							if(e.value === data.result.c_id){
								return (e.selected = true);
							}
						});

						Array.prototype.slice.call(this.$refs.company.children).some((e) => {
							if(e.value === data.result.u_id){
								return (e.selected = true);
							}
						});

					});

				}

			});



		}
	}
</script>
<style lang="scss" module>

	@import "../assets/scss/vars";

	.reg{
		padding: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;

		&__field, &__button{
			width: (100%/3)-1;
			margin-bottom: 5px;
		}

	}

</style>