<template>
	<form @submit.prevent="submit" style="position: relative">
		<slot></slot>
		<v-loader style="position: absolute;top:0;bottom:0;left:0;right:0;" v-if="loading"></v-loader>
	</form>
</template>

<script>
	export default {
		name : "v-form",
		data(){
			return {
				loading : false
			}
		},
		methods : {
			submit(){

				if(!this.loading){

					this.loading = true;
					this.$api.sendForm(this.$el).then(({data}) => {
						this.loading = false;
						this.$emit('success', data);
					});

				}

			}
		},
		created(){
			this.$on('submit', () => {
				this.submit();
			});
		}
	}
</script>

