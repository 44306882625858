<template>
	<section>
		<h1 class="section__h">
			<span>{{title}}</span>
			<a @click.prevent="saveEditor" class="btn btn--green" href="" v-if="opened">Сохранить</a>
			<a @click.prevent="openEditor" class="btn btn--blue" href="" v-if="!opened">Редактировать</a>
			<a @click.prevent="cancelEditor" class="btn btn--red" href="" v-if="opened">Отмена</a>
		</h1>
		<section class="section--white">
			<iframe :src="`/edit-template/`" height="400" width="100%" frameborder="0" ref="frame" v-if="opened" @load="initEditor"></iframe>
		</section>
	</section>
</template>
<script type="text/javascript">
	export default {
		name : 'v-template-item',
		props : {
			type : {
				required : true
			},
			title : {
				required : true
			}
		},
		data(){
			return {
				opened : false,
				oldResult : ''
			};
		},
		methods : {
			getNewResult(){
				return this.$refs.frame.contentWindow.getContent();
			},
			initEditor(){
				this.$refs.frame.contentWindow.setContent(this.oldResult);
			},
			saveEditor(){
				this.$api('templates.add', {type : this.type, html : this.getNewResult()}).then(({data}) => {
					alert('Сохранено!');
					this.oldResult = this.getNewResult();
					this.opened = false;
				});
			},
			openEditor(){
				this.opened = true;
			},
			cancelEditor(){
				this.opened = false;
			}
		},
		mounted(){

			this.$api('templates.get', {type : this.type}).then(({data}) => {
				this.oldResult = data.error ? '' : data.result;
			});

		}
	}
</script>
