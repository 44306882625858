<template>
	<header class="section" :class="$getStyle.set($style).add('header').list">
		<section class="section section--mini" :class="$getStyle.set($style).add('header', 'in').list">
			<div :class="$getStyle.set($style).add('header', 'profile').list">
				<a :class="$getStyle.set($style).add('header', 'profile-name').list">{{$root.companyData.u_name}}</a>
				<p :class="$getStyle.set($style).add('header', 'profile-info').list">{{$root.companyData.u_place}}</p>
				<p :class="$getStyle.set($style).add('header', 'profile-info').list">{{$root.companyData.u_mail}}</p>
				<p :class="$getStyle.set($style).add('header', 'profile-info').list">ИНН {{$root.companyData.u_inn}}, Р/С {{$root.companyData.u_rs}}</p>
			</div>
			<div :class="$getStyle.set($style).add('header', 'profile', 'green').list">
				<a :class="$getStyle.set($style).add('header', 'profile-name').list">{{$root.clientData.c_name}}</a>
				<p :class="$getStyle.set($style).add('header', 'profile-info').list">{{$root.clientData.c_place}}</p>
				<p :class="$getStyle.set($style).add('header', 'profile-info').list">{{$root.clientData.c_mail}}</p>
				<p :class="$getStyle.set($style).add('header', 'profile-info').list">ИНН {{$root.clientData.c_inn}}, Р/С {{$root.clientData.c_rs}}</p>
			</div>
			<div class="section section--white" :class="$getStyle.set($style).add('header', 'col').list">
				<i :class="$getStyle.set($style).add('header', 'col-icon').list"></i>
				<span :class="$getStyle.set($style).add('header', 'col-number').list">{{statistic.bills}}</span>
				<span :class="$getStyle.set($style).add('header', 'col-desc').list">Выставленные счета</span>
			</div>
			<div class="section section--white" :class="$getStyle.set($style).add('header', 'col').list">
				<i :class="$getStyle.set($style).add('header', 'col-icon').list"></i>
				<span :class="$getStyle.set($style).add('header', 'col-number').list">{{statistic.pays}}</span>
				<span :class="$getStyle.set($style).add('header', 'col-desc').list">Оплаченные счета</span>
			</div>
			<div class="section section--white" :class="$getStyle.set($style).add('header', 'col').list">
				<i :class="$getStyle.set($style).add('header', 'col-icon').list"></i>
				<span :class="$getStyle.set($style).add('header', 'col-number').list">{{statistic.acts}}</span>
				<span :class="$getStyle.set($style).add('header', 'col-desc').list">Закрывающие документы</span>
			</div>
		</section>
	</header>
</template>

<script>
	export default {
		name : "v-header-client",
		data(){

			return {
				statistic : {}
			};

		},
		created(){

			this.$api('documents.statistic').then(({data}) => {
				this.statistic = data.result;
			});

		},
		mounted(){



		}
	}
</script>

<style lang="scss" module>

	@import "../assets/scss/vars";

	.header{
		margin-top: 10px;
		margin-bottom: 50px;

		&__in{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&__profile{
			background: $COLOR_BLUE_DARK;
			padding: 30px 50px;
			color: $COLOR_WHITE;
			width: 24%;

			&--green{
				background: $COLOR_GREEN;
			}

		}

		&__profile-name{
			font-weight: bold;
			margin-bottom: 5px;
			display: block;

			&:hover{
				text-decoration: underline;
			}

		}

		&__profile-info{
			font-size: 0.8em;
			margin-bottom: 2px;
		}

		&__col{
			padding: 10px 20px;
			text-align: center;
			border-top: 10px solid $COLOR_BLUE;
			width: 15%;
		}

		&__col-number{
			color: $COLOR_BLUE_DARK;
			display: block;
			font-size: 2.6em;
		}

		&__col-desc{
			margin-top: 5px;
			color: $COLOR_GRAY;
		}

	}

</style>