<template>
	<section class="section section--mini">
		<h1 class="section__h">Просмотр клиентов</h1>
		<section class="section section--white">
			<table :class="$getStyle.set($style).add('table').list" ref="table">
				<v-clients-item v-for="item of $root.clients" :item="item"></v-clients-item>
			</table>
			<br>
			<v-loader v-if="loading"></v-loader>
			<p style="text-align: center;color: dimgray;padding: 15px;" v-if="!loading && !items.length">Ничего не найдено</p>
		</section>
	</section>
</template>
<script type="text/javascript">
	export default {
		name : 'page-clients',
		data (){
			return {
				items : [],
				loading : false,
				page : 0,
				limit : 30
			};
		},
		components : {
			'v-clients-item' : require('../components/clients-item.vue').default
		}
	};
</script>
<style module lang="scss">

	@import "../assets/scss/vars";

	.table{
		width: 100%;
	}

</style>