<template>
	<tr :class="$getStyle.set($style).add('tr', null, {'act' : item.d_type == 2, 'doc-confirm' : item.d_type == 3}).list">
		<td :class="$getStyle.set($style).add('tr', 'td').list">
			<span class="inf inf--blue">
				<template v-if="item.d_type == 1 || item.d_type == 3">Счет </template>
				<template v-if="item.d_type == 2">Акт </template>
				<template v-if="item.d_type == 4">Договор </template>
				<template>№{{item.d_id}}</template>
			</span>
		</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">
			<span class="inf inf--green" v-if="+item.d_auto">Авто</span>
		</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">
			<p>
				<template v-if="item.d_type == 1 || item.d_type == 3">От {{getCompany(item.u_id).u_name}}</template>
				<template v-if="item.d_type == 2">От {{getCompany(item.u_id).u_name}} по счету <a style="color: #34aadc" :href="`/api/?method=documents.get&id=${item.d_parent}`">№{{item.d_parent}}</a></template>
				<template v-if="item.d_type == 4">С {{getCompany(item.u_id).u_name}}</template>
			</p>
			<p :class="$getStyle.set($style).add('tr', 'hint').list">
				<span v-if="item.d_sum > 0">{{item.d_sum}} руб. , </span>
				<span>выставлен {{item.text_date}}</span>
			</p>
		</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list" style="font-size: 0.9em;">
			<template v-if="item.d_time_duration > 0">
				<p>Услуга с {{item.text_time_start}} на {{getDelay(item.d_time_duration)}}</p>
				<p :class="$getStyle.set($style).add('tr', 'hint').list">до {{item.text_time_end}}</p>
			</template>
		</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">
			<p>
				<span class="inf inf--gray" v-if="item.d_type == 1">Не оплачен</span>
				<span class="inf inf--blue" v-if="item.d_type == 2">Выставлен</span>
				<span class="inf inf--green" v-if="item.d_type == 3">Оплачен {{item.text_time_pay}}</span>
				<span class="inf inf--blue" v-if="item.d_type == 4">Заключен</span>
			</p>
			<p :class="$getStyle.set($style).add('tr', 'hint').list">
				<span v-if="item.d_type == 3">
					<span v-if="item.d_time_act > 0">Акт будет {{item.text_time_act}}</span>
					<span v-else>Акт выставлен</span>
				</span>
				<span v-if="item.d_type == 1">
					<span v-if="item.d_time_act > 0">Акт будет спустя {{getDelay(item.d_time_act)}}</span>
					<span v-if="item.d_time_act < 0">Акта не будет</span>
					<span v-if="!item.d_time_act">Акт будет сразу</span>
				</span>
			</p>
		</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list" v-if="$root.authorized">
			<a :href="`/archive/?user=${item.c_id}`" class="inf inf--blue">{{getUser(item.c_id)}}</a>
		</td>
		<td :class="$getStyle.set($style).add('tr', 'td').list">
			<a class="btn btn--blue" :href="`/archive/get/?id=${item.d_id}`" target="_blank" style="opacity: 0.8;">
				<img src="../assets/images/icons/open.svg" alt="" width="16">
			</a>
			<template v-if="$root.authorized">
				<a class="btn btn--gray" @click.prevent="mail" v-if="item.d_type == 4 || ((item.d_type == 1 || item.d_type == 3) && +item.d_auto)">
					<img src="../assets/images/icons/mail.svg" alt="" width="16">
				</a>
				<a class="btn btn--red" :href="`/archive/edit/?id=${item.d_id}`" v-if="item.d_type == 1 || item.d_type == 3">
					<img src="../assets/images/icons/edit.svg" alt="" width="16">
				</a>
				<a class="btn btn--green" href="#" @click.prevent="status" v-if="item.d_type == 1">
					<img src="../assets/images/icons/check.svg" alt="" width="16">
				</a>
				<a class="btn btn--red" href="#" @click.prevent="del"  v-if="item.d_type == 2">
					<img src="../assets/images/icons/delete.svg" alt="" width="16">
				</a>
			</template>
			<v-loader v-if="loading" :class="$getStyle.set($style).add('tr', 'loader').list"></v-loader>
		</td>
	</tr>
</template>

<script>
	export default {
		name : "v-archive-item",
		data(){
			return {
				loading : false
			}
		},
		props : {
			item : {
				required : true
			}
		},

		methods : {
			getDelay(x){

				x /= 3600;

				if(x >= 24){
					x /= 24;
					x = Math.ceil(x);
					x += ' дн.';
				}else{
					x = Math.ceil(x);
					x += ' ч.';
				}

				return x;

			},
			getUser(id){

				if(this.$root.authorized){

					for(let e of this.$root.clients){
						if(e.c_id == id){
							return e.c_name + ' #' + id;
						}
					}

				}else{
					return this.$root.clientData;
				}

			},
			getCompany(id){

				for(let e of this.$root.companies){
					if(e.u_id == id){
						return e;
					}
				}

			},
			edit(target){

				let form = new FormData;

				if(target.files.length){
					this.loading = true;

					form.append('file', target.files[0]);
					form.append('id', this.item.d_id);


					this.$api('documents.edit', form).then(({data}) => {

						alert(data.error || 'Обновлено!');
						this.loading = false;

					});

				}

			},
			status(){

				if(confirm('Подтвердите действие!')){
					this.loading = true;

					this.$api('documents.confirm', {id : this.item.d_id}).then(({data}) => {

						if(data.result){
							for(let i in data.result){
								this.$set(this.item, i, data.result[i]);
							}
						}else{
							alert(data.error);
						}

						this.loading = false;

					});

				}



			},
			mail(){
				if(confirm('Подтвердите действие!')){

					this.loading = true;

					this.$api('documents.mail', {id : this.item.d_id}).then(({data}) => {

						if(data.error){
							alert(data.error);
						}else{
							alert('Документ выслан на E-mail');
						}

						this.loading = false;

					});
				}
			},
			del(){

				if(confirm('Подтвердите действие!')){

					this.loading = true;

					this.$api('documents.delete', {id : this.item.d_id}).then(({data}) => {

						if(data.error){
							alert(data.error);
						}else{
							this.$emit('delete');
						}

						this.loading = false;

					});
				}

			}
		}
	}
</script>

<style module lang="scss">

	@import "../assets/scss/vars";

	.tr{
		position: relative;
		transition: all 0.5s;

		&--act{
			background: #ceecff;
		}

		&--doc-confirm{
			background: #e3ffe2;
		}

		&__td{
			padding: 10px 15px;
			border-bottom: 1px solid rgba($COLOR_BLACK, 0.1);

			&:last-child{
				text-align: right;
			}

		}

		&__loader{
			background: rgba($COLOR_WHITE, 0.7);
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
		}

		&__hint{
			font-size: 0.83em;
			margin: 3px 0;
			color: darkgray;
		}

	}



</style>
