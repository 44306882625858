<template>
	<header class="section" :class="$getStyle.set($style).add('header').list">
		<section class="section section--mini" :class="$getStyle.set($style).add('header', 'in').list" v-if="$root.authorized">
			<div :class="$getStyle.set($style).add('header', 'profile').list">
				<template v-if="+$root.accountData.u_active">
					<a href="/profile/" :class="$getStyle.set($style).add('header', 'profile-name').list">{{$root.companyData.u_name}}</a>
					<a href="/exit/" :class="$getStyle.set($style).add('header', 'profile-exit').list">[Выйти]</a>
					<p :class="$getStyle.set($style).add('header', 'profile-info').list">{{$root.companyData.u_place}}</p>
					<p :class="$getStyle.set($style).add('header', 'profile-info').list">{{$root.companyData.u_mail}}</p>
					<p :class="$getStyle.set($style).add('header', 'profile-info').list">
						ИНН {{$root.companyData.u_inn}}, Р/С {{$root.companyData.u_rs}}
					</p>
				</template>
				<template v-else>
					<p :class="$getStyle.set($style).add('header', 'profile-info').list">
						Заполните реквизиты в <a href="/profile/" style="text-decoration: underline"><b>профиле</b></a>
					</p>
				</template>
			</div>
			<div class="section section--white" :class="$getStyle.set($style).add('header', 'col').list">
				<a href="/clients/" :class="$getStyle.set($style).add('header', 'col-name').list">Управление клиентами</a>
				<p :class="$getStyle.set($style).add('header', 'col-desc').list">Просматривайте своих клиентов и их счета</p>
				<a href="/clients/add/" :class="$getStyle.set($style).add('header', 'col-num').list">Добавить клиента</a>
			</div>
			<div class="section section--white" :class="$getStyle.set($style).add('header', 'col').list">
				<a href="/archive/" :class="$getStyle.set($style).add('header', 'col-name').list">Просмотр документов</a>
				<p :class="$getStyle.set($style).add('header', 'col-desc').list">Тут вы можете найти ваши документы</p>
				<a href="/archive/add/" :class="$getStyle.set($style).add('header', 'col-num').list">Выставить счет</a>
			</div>
			<div class="section section--white" :class="$getStyle.set($style).add('header', 'col').list">
				<a href="/profile/" :class="$getStyle.set($style).add('header', 'col-name').list">Профиль</a>
				<p :class="$getStyle.set($style).add('header', 'col-desc').list">Вы можете изменить данные профиля</p>
				<a href="/include/" :class="$getStyle.set($style).add('header', 'col-num').list">Встроить на сайт</a>
			</div>
		</section>
		<section class="section section--mini" :class="$getStyle.set($style).add('header', 'in').list" v-else>
			<div class="section section--white" :class="$getStyle.set($style).add('header', 'col').list">
				<a href="/auth/" :class="$getStyle.set($style).add('header', 'col-name').list">Вход</a>
				<a href="/register/" :class="$getStyle.set($style).add('header', 'col-num').list">Регистрация</a>
			</div>
		</section>
	</header>
</template>

<script>
	export default {
		name : "v-header"
	}
</script>

<style lang="scss" module>

	@import "../assets/scss/vars";

	.header{
		margin-top: 10px;
		margin-bottom: 50px;

		&__in{
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}

		&__profile{
			position: relative;
			background: $COLOR_BLUE_DARK;
			padding: 30px 50px;
			color: $COLOR_WHITE;
			width: 24%;
		}

		&__profile-exit{
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 0.8em;

			&:hover{
				text-shadow: 0 0 3px white;
			}

		}

		&__profile-name{
			font-weight: bold;
			margin-bottom: 5px;
			display: block;

			&:hover{
				text-decoration: underline;
			}

		}

		&__profile-info{
			font-size: 0.8em;
			margin-bottom: 2px;
		}

		&__col{
			padding: 10px 20px;
			text-align: center;
			border-top: 10px solid $COLOR_BLUE;
			width: 18%;
		}

		&__col-name{
			color: $COLOR_BLUE;
			font-size: 1.2em;
			font-weight: bold;

			&:hover{
				text-decoration: underline;
			}

		}

		&__col-desc{
			font-size: 0.8em;
			margin-top: 3px;
			opacity: 0.5;
		}

		&__col-num{
			background-color: $COLOR_BLUE;
			color: $COLOR_WHITE;
			margin-top: 5px;
			padding: 3px 5px;
			display: block;
			border-radius: 3px;
			font-size: 0.8em;

			&:hover{
				opacity: 0.8;
			}

		}

	}

</style>