<template>
	<div :class="$getStyle.set($style).add('loader').list">
		<i :class="$getStyle.set($style).add('loader', 'icon').list"></i>
	</div>
</template>
<script>
	export default {
		name : "v-loader"
	}
</script>

<style module lang="scss">

	.loader{
		color: #1784cd;
		text-align: center;

		&__icon{
			mask-image: url("../assets/images/loader.svg");
			background-size: 100%;
			width: 32px;
			height: 32px;
			display: inline-block;
			background: currentColor;
		}

	}

</style>