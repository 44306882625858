<template>
	<section class="section section--mini" style="width: 500px;">
		<h1 class="section__h">Выставление нового счета</h1>
		<section class="section section--white">
			<v-form action="documents.generate.bill" @success="submit" :class="$getStyle.set($style).add('reg').list">
				<div class="field field--fill" :class="$getStyle.set($style).add('reg', 'field').list">
					<span class="field__legend">Клиент</span>
					<select class="select" name="user" ref="user">
						<option :value="u.c_id" v-for="u in $root.clients">{{u.c_name}}</option>
					</select>
				</div>
				<div class="field field--fill" :class="$getStyle.set($style).add('reg', 'field').list">
					<span class="field__legend">Сумма</span>
					<input type="text" placeholder="Сумма" name="sum" class="input">
				</div>
				<button class="button" :class="$getStyle.set($style).add('reg', 'button').list">Создать</button>
			</v-form>
		</section>

	</section>
</template>
<script type="text/javascript">
	export default {
		name : 'page-archive-add',
		props : {
			user : {
				default : 0
			}
		},
		methods : {
			submit(data){
				alert(data.error || "Успешно!");
			}
		},
		async mounted(){

			this.$nextTick(() => {
				Array.prototype.slice.call(this.$refs.user.children).some((e) => {
					if(e.value === this.user){
						return (e.selected = true);
					}
				});
			});

		}
	}
</script>
<style lang="scss" module>

	@import "../assets/scss/vars";

	.reg{
		padding: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;

		&__field, &__button{
			width: (100%/3)-1;
			margin-bottom: 5px;
		}

	}

</style>