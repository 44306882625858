import mainLess from '../assets/scss/main.scss';

import Vue from 'vue';
import axios from 'axios';

import VuePlugin_Axios from 'vue-axios';
import VuePlugin_EventBus from '../plugins/event-bus';
import VuePlugin_VueStyle from '../plugins/styles';
import VuePlugin_Api from '../plugins/api';

axios.defaults.headers.common = {'X-Requested-With': 'XMLHttpRequest'};

Vue.use(VuePlugin_Axios, axios);
Vue.use(VuePlugin_VueStyle);
Vue.use(VuePlugin_EventBus);
Vue.use(VuePlugin_Api);

Vue.prototype.$mainStyle = mainLess;

Vue.component('page-register', require('../pages/register.vue').default);
Vue.component('page-register-client', require('../pages/register-client.vue').default);
Vue.component('page-auth', require('../pages/auth.vue').default);
Vue.component('page-profile', require('../pages/profile.vue').default);
Vue.component('page-archive', require('../pages/archive.vue').default);
Vue.component('page-include', require('../pages/include.vue').default);
Vue.component('page-archive-add', require('../pages/archive-add.vue').default);
Vue.component('page-archive-edit', require('../pages/archive-edit.vue').default);
Vue.component('page-clients', require('../pages/clients.vue').default);
Vue.component('page-cabinet', require('../pages/cabinet.vue').default);
Vue.component('page-index', require('../pages/page-index.vue').default);
Vue.component('v-loader', require('../components/loader.vue').default);
Vue.component('v-header', require('../components/header.vue').default)
Vue.component('v-header-client', require('../components/header-client.vue').default);
Vue.component('v-footer', require('../components/footer.vue').default);
Vue.component('v-form', require('../components/form.vue').default);
Vue.component('v-slider', require('../components/slider.vue').default);
Vue.component('v-template-item', require('../components/template-item.vue').default);

document.addEventListener('DOMContentLoaded', () => {

	window.app = new Vue({
        el: '#wrapper',
        components: {
        },
        data: {
	        userData : null,
	        clients : [],
	        companies : []
        },
		methods : {
            reload(){
            	window.location.reload();
            },
			assign(str){
            	window.location.assign(str);
			}
		},
        computed: {
          clientData(){
              return this.userData.client;
          },
	      accountData(){
          	return this.userData.account;
	      },
	      companyData(){
          	return this.userData.company;
          },
           authorized(){
               return this.userData && ('account' in this.userData);
           },
           invited(){
               return this.userData && ('client' in this.userData);
           }
        },
        created() {

            if('Token' in window){
                this.$api.setToken(window.Token);
            }

            if('CSRF' in window){
            	this.$api.setCSRF(window.CSRF);
            }

	        this.$api('users.info').then(async ({data}) => {
		        if(!data.error){

			        if('account' in data.result){
				        await this.$api('companies.clients').then(({data}) => {
				        	this.clients = data.result;
				        });
			        }

			        await this.$api('companies.list').then(({data}) => {
				        this.companies = data.result;
			        });

			        this.userData = data.result;

		        }
	        });

        }
    });

});