<template>
	<section>
		<div ref="slider"></div>
		<input type="hidden" :name="name" ref="input">
	</section>
</template>

<script>
	import Slider from 'nouislider';

	export default {
		name : "v-slider",
		data(){
			return {
				slider : null
			}
		},
		props : {
			name : {
				default : false
			},
			options : {
				default : {}
			}
		},
		methods : {

		},
		mounted(){
			Slider.create(this.$refs.slider, this.options);

			this.slider = this.$refs.slider.noUiSlider;

			this.slider.on('update', (x,y,value) => {

				if(value instanceof Array){
					value = value.pop();
				}

				this.$refs.input.value = value;

			});

		}
	}
</script>

<style module lang="scss">




</style>