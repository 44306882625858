export default {
	'install' : function(Vue){

		let token = false,
		    csrf = false;

		function request(api, data){

			let url = '/api/?method=' + api,
			    method = 'post',
			    headers = {
					'content-type': 'application/json'
			    };

			if(token){
				headers.Authorization = `Token ${token}`;
			}
			if(csrf){
				headers.Csrf = csrf;
			}

			return Vue.prototype.$http({method, url, data, headers});

		}

		request.setToken = (t) => {	token = t;	};
		request.setCSRF = (t) => { csrf = t; }

		request.sendForm = function(ev){

			let data = new FormData, elem, value;
			for(elem of ev.elements){

				if(elem.type === 'radio' || elem.type === 'checkbox' && !elem.checked){
					continue;
				}

				if(elem.type === 'file'){
					value = elem.files[0];
				}else{
					value = elem.value;
				}

				data.append(elem.name, value);
			}

			return request(ev.getAttribute("action"), data);

		};


		Vue.prototype.$api = request;

	}
};